<template>
  <div class="position-relative zq--column-filter">
    <input
        type="text"
        class="form-control form-control-sm"
        v-model="filterItem"
    />
<!--    <CIcon-->
<!--        name="cil-x"-->
<!--        @click.native="filterItem = null"-->
<!--        class="cil-x-icon position-absolute zq&#45;&#45;column-filter&#45;&#45;icon"-->
<!--    ></CIcon>-->
  </div>
</template>
<script>
export default {
  name: 'ColumnFilter',
  props: {
    field: String,
  },
  data() {
    return {
      filterItem: '',
    };
  },

  watch: {
    filterItem: {
      deep: true,
      handler: function(val) {
        this.$emit('handleChangeData', { value: val, field: this.field });
      },
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.cil-x-icon {
  top: 5px;
  right: 5px;
}
</style>
