<template>
  <div class="messages">
    <!--   Header   -->
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title">Inbox</h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>
    <div class="content">
      <CRow>
        <CCol col="12">
          <TableHeader
            :originalFields="originalFields"
            :fields="fields"
            :statuses="statuses"
            :isVisible="selectedMessages.length"
            :disabled="!ready"
            :isCreateButtonVisible="false"
            @updateFields="updateFields"
            @createItem="createMessage"
            @toggleFilter="showColumnFilter"
            @checkStatus="checkStatus"
            @searchData="searchMessages"
            @clearFilter="clearFilter"
            @deleteData="deleteMessages"
          />
        </CCol>
      </CRow>
      <CRow class="mt-1">
        <CCol col="12">
          <CCard class="zq-card-table">
            <CCardBody>
              <CDataTable
                id="inboxMessage-table"
                ref="inboxMessageTable"
                class="zq--table"
                :items="messagesData"
                :fields="fields"
                :column-filter="columnFilter"
                :items-per-page="itemsPerPage"
                :sorter="{ external: true }"
                :responsive="true"
                :border="isDefaultTheme"
                :hover="isDefaultTheme"
                :striped="isDefaultTheme"
                :loading="loading"
                @pagination-change="itemsPerPageSelect"
                @page-change="paginationChange"
                @update:sorter-value="handleSortBy"
                @update:column-filter-value="handleColumnFilter"
                :sorter-value.sync="sorterValue"
              >
                <template #loading>
                  <TableLoader/>
                </template>
                <template #id="{ item }">
                  <td
                    class="fixed-column-id"
                    :class="{'column-shadow-start': isTableScrollbar}"
                  >
                    <router-link :to="{name:`Inbox`, params: {id: item.id} }">
                      {{ item.id }}
                    </router-link>
                  </td>
                </template>
                <template #created="{ item }">
                  <td>{{ dateFormate(item.created) }}</td>
                </template>
                <template #transactionTimestamp="{ item }">
                  <td>{{ dateFormate(item.transactionTimestamp) }}</td>
                </template>
                <template #pending="{ item }">
                  <td>
                    <label class="c-switch c-switch-pill c-switch-label c-switch-success mt-2 ml-2">
                      <input
                        v-if="item.pending==='true'"
                        type="checkbox"
                        class="c-switch-input"
                        :checked="true"
                        :disabled="true"
                      >
                      <input
                        v-else
                        type="checkbox"
                        class="c-switch-input"
                        :checked="false"
                        :disabled="true"
                      >
                      <span class="c-switch-slider" data-checked="Yes" data-unchecked="No"></span>
                    </label>
                  </td>
                </template>
                <template #status="{item}">
                  <td>
                    <div
                      v-if="item.status === 'New'"
                      :class="{'status-box__new': item.status === 'New'}"
                      class="status-box"
                    >
                      {{ item.status }}
                    </div>
                    <div
                      v-if="item.status === 'Read'"
                      :class="{'status-box__read': item.status === 'Read'}"
                      class="status-box"
                    >
                      {{ item.status }}
                    </div>
                    <div
                      v-if="item.status === 'Actioned'"
                      :class="{'status-box__actioned': item.status === 'Actioned'}"
                      class="status-box"
                    >
                      {{ item.status }}
                    </div>
                    <div
                      v-if="item.status === 'CollaborationRequest'"
                      :class="{'status-box__collaborationRequest': item.status === 'CollaborationRequest'}"
                      class="status-box"
                    >
                      {{ item.status }}
                    </div>
                    <div
                      v-if="item.status === 'Claimed'"
                      :class="{'status-box__claimed': item.status === 'Claimed'}"
                      class="status-box"
                    >
                      {{ item.status }}
                    </div>
                    <div
                      v-if="item.status === 'Expired'"
                      :class="{'status-box__expired': item.status === 'Expired'}"
                      class="status-box"
                    >
                      {{ item.status }}
                    </div>
                    <div
                      v-if="item.status === 'Canceled'"
                      :class="{'status-box__canceled': item.status === 'Canceled'}"
                      class="status-box"
                    >
                      {{ item.status }}
                    </div>
                    <div
                      v-if="item.status === 'Deprecated'"
                      :class="{'status-box__deprecated': item.status === 'Deprecated'}"
                      class="status-box"
                    >
                      {{ item.status }}
                    </div>
                  </td>
                </template>
                <template #id-filter>
                  <FilterMessage
                    field="id"
                    @handleChangeData="handleColumnFilter"
                  />
                </template>
                <template #memberGroup-filter>
                  <FilterMessage
                    field="added"
                    @handleChangeData="handleColumnFilter"
                  />
                </template>
                <template #members-filter>
                  <FilterMessage
                    field="spaceName"
                    @handleChangeData="handleColumnFilter"
                  />
                </template>
                <template #messageType-filter>
                  <FilterMessage
                    field="id"
                    @handleChangeData="handleColumnFilter"
                  />
                </template>
                <template #subject-filter>
                  <FilterMessage
                    field="name"
                    @handleChangeData="handleColumnFilter"
                  />
                </template>
                <template #body-filter>
                  <FilterMessage
                    field="surname"
                    @handleChangeData="handleColumnFilter"
                  />
                </template>
                <template #status-filter>
                  <FilterMessage
                    field="email"
                    @handleChangeData="handleColumnFilter"
                  />
                </template>
                <template #translatableFields-filter>
                  <FilterMessage
                    field="role"
                    @handleChangeData="handleColumnFilter"
                  />
                </template>
                <template #constraints-filter>
                  <FilterMessage
                    field="pending"
                    @handleChangeData="handleColumnFilter"
                  />
                </template>
                <template #scheduling-filter>
                  <FilterMessage
                    field="pending"
                    @handleChangeData="handleColumnFilter"
                  />
                </template>
                <template #translations-filter>
                  <FilterMessage
                    field="pending"
                    @handleChangeData="handleColumnFilter"
                  />
                </template>
                <template #select-header class="text-center">
                  <div class="position-relative zq--table-select-all" v-theme-select-all>
                    <ClCheckbox
                      :checkedProp="toggleFlag"
                      icon="fa-angle-down"
                      @handler="selectAll"
                    />
                  </div>
                </template>
                <template #select-filter>
                  <div></div>
                </template>
                <template #select="{ item }">
                  <td :data-id="item.id" class="select-td fixed-column-start">
                    <ClCheckbox
                      :valueProp="item.id"
                      :checkedProp="selectedMessages.indexOf(item.id) > -1"
                      @handler="checkMessages"
                    />
                  </td>
                </template>
                <template #references="{ item }">
                  <td>
                    <span class="text-ellipsis overflow-hidden text-nowrap d-block">
                      {{ getReferences(item.references) }}
                    </span>
                  </td>
                </template>
                <template #actions-header>
                  <div v-theme-header-actions></div>
                </template>
                <template #actions-filter>
                  <div></div>
                </template>
                <template #actions="{ item }">
                  <ClTableActionsDropdown
                    @choice="dropDownChoice"
                    :itemProp="item"
                    :actionsProp="dropDownActions"
                    :isTableScrollbarProp="isTableScrollbar"
                  />
                </template>
              </CDataTable>
              <TableFooter
                :page="page"
                @updatePagenation="paginationChange"
                :pages="pages"
                :total="resultCount"
                :itemsPerPage="itemsPerPage"
                :disabled="!ready"
                @updateItemPerPage="itemsPerPageSelect"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <!--  MODAL  -->
    <Modal
      :modalShow="deleteModal"
      :messageGeneral="messageGeneral"
      :title="deleteTitle"
      @doFunction="deleteMessage"
      v-on:toggle-modal="closeModal('delete')"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import * as _ from 'lodash';
import {pageConfig} from '@/config';
import {dateFormate} from '@/utils/dateFormate'
import TableFooter from '@/components/table/Footer';
import ClCheckbox from '@/shared/components/formComponents/ClCheckbox';
import TableHeader from '@/components/table/Header';
import TableLoader from "@/components/table/Loader";
import FilterMessage from '@/components/table/ColumnFilter';
import Modal from '@/shared/UI/Modal';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import {checkNullItem} from "@/helpers/checkNullItem";
import ClTableActionsDropdown from '@/shared/components/ClTableActionsDropdown';
import TableResizeMixin from "@/shared/mixins/TableResizeMixin";
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import {messaging} from "@/config/descriptions/messaging";

export default {
  name: 'Messages',
  components: {
    TableFooter,
    TableHeader,
    TableLoader,
    ClTableActionsDropdown,
    FilterMessage,
    Modal,
    ClCheckbox,
    IconWithTooltip
  },
  data() {
    return {
      descriptions: {
        ...messaging.inbox
      },
      messagesData: [],
      customFieldCreateOrLoadConfig: {
        title: "Welcome to the Messages page. To get started here please select language from the drop down below",
        createTitle: "Create a Collaborator",
        path: 'CreateCollaborator'
      },
      details: [],
      collapseDuration: 0,
      itemsPerPage: pageConfig.itemsPerPage,
      query: {},
      hasAtLeastOne: true,
      ready: false,
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      columnFilter: false,
      status: 'all',
      statuses: [],
      deleteModal: false,
      messageGeneral: 'You are about to delete a message , Are you sure?',
      selectedId: null,
      selectedMessages: [],
      toggleFlag: false,
      deleteTitle: "Delete Collaborator",
      pending: null,
      activeToggleId: null,
      isTableScrollbar: false,
      dropDownActions: [
        {
          title: this.$t('buttons.preview'),
          handler: 'showPreview',
        },
        {
          title: this.$t('buttons.edit'),
          handler: 'editMessage',
        },
        {
          title: this.$t('buttons.delete'),
          handler: 'deleteMessage',
        },
      ],
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('messages', [
      'messages',
      'fields',
      'pages',
      'loading',
      'resultCount',
      'originalFields',
      'success',
      'message',
    ]),
    ...mapGetters('theme', ['theme']),
    sorterValue: {
      get() {
        const sorter = (this.sortBy || [])[0];
        const key = sorter ? sorter.order.toLowerCase() : undefined;
        if (!key) return undefined;

        return {
          column: sorter.queryField,
          desc: key === 'desc',
          asc: key === 'asc'
        };
      },
      set(val) {
        this.sortBy[0] = {
          queryField: val.column,
          order: val.asc ? 'Asc' : 'Desc',
        }
      }
    },
    isDefaultTheme() {
      return this.theme === 'default'
    }
  },
  watch: {
    messages() {
      this.messagesData = checkNullItem(this.messages);
    }
  },
  mixins: [
    TableResizeMixin('inboxMessageTable')
  ],
  methods: {
    ...mapActions('messages', [
      'handleGetAccountMessages',
      'handleFields',
      'handleDeleteMessages',
      'handleDeleteAccountMessages',
      'handleClearFilter',
    ]),
    initialize() {
      this.handleGetAccountMessages({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      }).then((items) => {
        this.hasAtLeastOne = !!items.length
        this.ready = true;
        routerBreadcrumbs(this.$router.currentRoute)
        this.pending = items.pending;
      });
      this.handleFields();
    },
    // FIX Z-INDEX ISSUE
    handleToggle(id) {
      this.activeToggleId = id;
    },
    itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;
      this.handleGetAccountMessages({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
    paginationChange(val) {
      this.page = val;
      this.handleGetAccountMessages({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
    dateFormate(val) {
      return dateFormate(val)
    },
    updateFields(val) {
      this.handleFields(val);
    },
    createMessage() {
      this.$router.push({
        name: 'CreateDraft'
      });
    },
    showColumnFilter(val) {
      this.columnFilter = val;
      if (!val) {
        this.formatFilter();
      }
    },
    checkStatus(val) {
      this.status = val;
    },
    formatFilter() {
      this.$refs.inboxMessageTable.clean();
      this.page = 1;
      this.query = {};
      this.sortBy = [
        {
          queryField: 'created',
          order: 'Desc',
        },
      ];
      this.handleGetAccountMessages({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
    handleSortBy(e) {
      this.sortBy = [
        {
          queryField: e.column,
          order: e.asc ? 'ASC' : 'Desc',
        },
      ];
      this.page = 1;
      this.handleGetAccountMessages({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
    handleColumnFilter(e) {
      if (e.field && e.value) {
        this.query[e.field] = e.value.trim();
      } else {
        delete this.query[e.field];
      }
      this.handleGetAccountMessages({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
    searchMessages(val) {
      this.query = {name: val};
      if (val === '') {
        this.query = {};
      }
      this.handleGetAccountMessages({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
    },
    deleteMessage() {
      this.deleteModal = false;
      this.handleDeleteAccountMessages({
        id: this.selectedId,
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
      this.selectedMessages = [];
      this.toggleFlag = false;
    },
    closeModal() {
      this.deleteModal = false
    },
    getReferences(items) {
      let references = '';
      if (items && items.length > 0)
        items.forEach((element, index) => {
          if (index === items.length - 1) {
            references += element;
          } else references += element + ', ';
        });
      return references;
    },
    clearFilter() {
      this.handleClearFilter();
    },
    deleteMessages() {
      this.messageGeneral = 'You are about to delete ' + this.selectedMessages.length + ' messages , Are you sure?';
      this.deleteModal = true;
      this.selectedId = _.cloneDeep(this.selectedMessages);
    },
    checkMessages({event}) {
      const index = this.selectedMessages.indexOf(event.target.value);
      if (index > -1) {
        this.selectedMessages.splice(index, 1);
      } else {
        this.selectedMessages.push(event.target.value);
      }
    },
    showPreview(item) {
      this.$router.push({
        name: 'PreviewAccountMessage',
        params: {
          id: item.id,
        }
      });
    },
    editMessage(item) {
      this.$router.push({
        name: 'Edit Account Message',
        params: {
          id: item.id,
        }
      });
    },
    dropDownChoice(action, item) {
      if (this[action]) {
        this[action](item)
      }
    },
  },
};
</script>
<style lang="scss">
.messages {
  height: 100%;
  .content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);
    border-top: 1px solid #d8dbe0;
    .text-ellipsis {
      text-overflow: ellipsis;
      width: 150px;
      max-width: 100%;
    }
  }
  .status-box {
    margin: 0 auto;
    border-radius: 16px;
    text-align: center;
    background-color: #bfe8ee;
    &__new {
      text-align: center;
      background-color: #d7f1d1;
    }
    &__read {
      text-align: center;
      background-color: #bfe8ee;
    }
    &__collaborationRequest {
      padding: 0 0.75rem;
      text-align: center;
      background-color: #FFC0CB;
    }
    &__actioned {
      padding: 0 0.75rem;
      text-align: center;
      background-color: #FFDAB9;
    }
    &__claimed {
      padding: 0 0.75rem;
      text-align: center;
      background-color: #F0E68C;
    }
    &__expired {
      padding: 0 0.75rem;
      text-align: center;
      background-color: #A9A9A9;
    }
    &__canceled {
      padding: 0 0.75rem;
      text-align: center;
      background-color: #F08080;
    }
    &__deprecated {
      padding: 0 0.75rem;
      text-align: center;
      background-color: #DCDCDC;
    }
  }
}
</style>